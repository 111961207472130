import React from 'react'
import LinkCard from '../../components/Cards/LinkCard'
import Seo from '../../components/Partials/Seo'
import ProjectTemplate from '../../components/Template/ProjectTemplate'

export default function DickaIsmaji() {
  return (
    <ProjectTemplate>
      <Seo
        title="Personal Website - Project"
        image="/images/dickaismaji-cover.png"
      />
      <h1>Dicka Ismaji Personal Website</h1>
      <div className="mb-4">
        <img className="w-full" src="/images/dickaismaji-cover.png" alt="Dicka Ismaji Personal Website" />
        <div className="mt-8 inline-block p-4 border-2 border-red-600 rounded-xl">
          <LinkCard
            web={"https://dickaismaji.com"}
            webName={"Dicka Ismaji Personal Website"}
          />
        </div>
      </div>
      <section id="about" className="pt-4">
        <h2 className="font-bold">Overview</h2>
        <p>This is my updated personal web, after a few day after I buy this domain on mid of november and then the website is launched. How the website is looks like, you can explore this website.</p>
      </section>
      <section id="technology" className="pt-4">
        <h2 className="font-bold">Technology</h2>
        <p>Design tool: Figma</p>
        <p>Tech tool: React, Gatsby JS, Tailwind CSS</p>
      </section>
    </ProjectTemplate>
  )
}